// Valentines.js
import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import heartImage from '../assets/images/heart.png';
import cryingImage from '../assets/images/crying.png';

const Valentines = () => {
  const [showHearts, setShowHearts] = useState(true);
  const [text, setText] = useState("Will you be my valentine Charlotte?");
  const [showYes, setShowYes] = useState(true);
  const [showNo, setShowNo] = useState(true);
  const [confetti, setConfetti] = useState(false);

  const containerRef = useRef(null);
  const mousePosition = useRef({ x: 0, y: 0 });
  const windowWidth = useRef(window.innerWidth);

  // Generate initial hearts
  const heartCount = 500;
  const generateHearts = () => Array.from({ length: heartCount }).map((_, i) => ({
    id: i,
    x: Math.random() * windowWidth.current,
    y: Math.random() * window.innerHeight,
    rotation: Math.random() * 360,
    scale: Math.random() * 0.5 + 0.5,
  }));

  const [hearts, setHearts] = useState(generateHearts);

  useEffect(() => {
    const handleMouseMove = (event) => {
      mousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const handleResize = () => {
      windowWidth.current = window.innerWidth;
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const updateHearts = () => {
      setHearts(prevHearts => prevHearts.map(heart => {
        const dx = mousePosition.current.x - heart.x;
        const dy = mousePosition.current.y - heart.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        let repulsionForce = 0;
        if (distance < 100) {
          repulsionForce = 20 * (1 - distance / 100);
        }

        const repulsionX = repulsionForce * (dx / distance || 0);
        const repulsionY = repulsionForce * (dy / distance || 0);

        return {
          ...heart,
          x: Math.max(0, Math.min(window.innerWidth, heart.x - repulsionX)),
          y: heart.y < 0 ? window.innerHeight : heart.y - 3 - repulsionY, // Hearts float up continuously
        };
      }));

      requestAnimationFrame(updateHearts);
    };

    requestAnimationFrame(updateHearts);
  }, []);

  const buttonVariants = {
    hover: { scale: 1.1 },
    tap: { scale: 0.95 },
  };

  const handleNoHover = (isHovering) => {
    if (showHearts) {setShowHearts(!isHovering)};
  };

  const handleYesClick = () => {
    setText("Hooray! 🎉");
    setShowNo(false); // Hide "No" button
    setConfetti(true); // Trigger confetti animation

    setTimeout(() => {
      setConfetti(false); // Hide confetti after a while
    }, 3000);
  };

  const handleNoClick = () => {
    setText("NOOOOO :(");
    setShowYes(false); // Hide "Yes" button
    setShowHearts(false); // Keep only crying faces
  };

  return (
    <div className="valentines-container" ref={containerRef}>
      <div className="content">
        <h1>{text}</h1>
        <div className="button-group">
          {showYes && (
            <motion.button
              className="yes-button"
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
              onClick={handleYesClick}
            >
              Yes
            </motion.button>
          )}
          {showNo && (
            <motion.button
              className="no-button"
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
              onMouseEnter={() => handleNoHover(true)}
              onMouseLeave={() => handleNoHover(false)}
              onClick={handleNoClick}
            >
              No
            </motion.button>
          )}
        </div>
      </div>

      {/* Confetti Animation */}
      {confetti && (
        <div className="confetti-container">
          {Array.from({ length: 100 }).map((_, i) => (
            <motion.div
              key={i}
              className="confetti"
              initial={{
                opacity: 1,
                x: Math.random() * window.innerWidth - window.innerWidth / 2,
                y: Math.random() * -window.innerHeight,
                rotate: Math.random() * 360,
              }}
              animate={{
                y: window.innerHeight,
                rotate: Math.random() * 360,
                opacity: 0,
              }}
              transition={{
                duration: Math.random() * 2 + 1,
                ease: "easeOut",
              }}
            />
          ))}
        </div>
      )}

      {/* Floating hearts or crying faces */}
      <div className="heart-container">
        {hearts.map((heart) => (
          <motion.div
            key={heart.id}
            className={`heart ${showHearts ? 'heart-icon' : 'emoji-icon'}`}
            style={{
              left: heart.x,
              top: heart.y,
              rotate: heart.rotation,
              scale: heart.scale,
              backgroundColor: showHearts ? heart.color : 'transparent',
              backgroundImage: showHearts ? `url(${heartImage})` : `url(${cryingImage})`,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        ))}
      </div>
    </div>
  );
};

export default Valentines;